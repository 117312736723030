<template>
  <div class="atom-image-label">
    <AppIcon icon="snake" class="atom-image-label__icon" :is-raw="true" />
    <div class="atom-image-label__content">
      <div class="atom-image-label__name">
        {{ data.name }}
      </div>
      <div class="atom-image-label__text">
        {{ data.text }}
      </div>

      <SlotLink
        v-if="isArrayNotEmpty(data.link) && !data.buyNowText"
        class="atom-image-label__link"
        :data="data.link[0]"
      >
        {{ data.link[0].label }}
      </SlotLink>

      <button
        v-if="data.buyNowText && mainProduct"
        type="button"
        class="atom-image-label__buy"
        @click="addProduct()"
      >
        {{ data.buyNowText }}
      </button>
    </div>
  </div>
</template>

<script setup>
defineProps({
    data: {
        type: Object,
        default: () => {},
    },
});

const pageData = inject('pageData', null);
const mainProduct = computed(() => pageData?.value?.mainProduct);

const cartStore = useCartStore();
const addProduct = async () => {
    if (!mainProduct.value) return;
    cartStore.addProduct(mainProduct.value, {
        openPopup: false,
        isAdd: true,
    });

    await navigateTo('/warenkorb');
};

</script>

<style lang="scss" scoped>
.atom-image-label {
    position: absolute;
    bottom: 60px;
    left: 192px;
    padding: 28px 41px 28px 65px;
    border-radius: 12px;
    background-color: $C_WHITE;

    .block-hero-landingpage__image & {
        right: 238px;
        bottom: 67px;
        left: unset;
    }

    &:before {
        position: absolute;
        top: 50%;
        left: -15px;
        width: 30px;
        height: 30px;
        border-radius: 90px;
        background: $C_WHITE;
        content: '';
        transform: translateY(-50%);
    }

    @include tablet {
        bottom: 110px;
        left: 66px;
    }

    @include tablet-portrait {
        bottom: 110px;
        left: 20px;
    }

    @include mobile {
        top: 35px;
        bottom: unset;
        left: 34px;

        .block-hero-landingpage__image & {
            top: unset;
            right: unset;
            bottom: 67px;
            left: 34px;
        }
    }
}

.atom-image-label__icon {
    position: absolute;
    top: 50%;
    left: -5px;
    width: 100%;
    transform: translateY(-50%);
}

.atom-image-label__content {
    display: flex;
    flex-direction: column;
}

.atom-image-label__name {
    @include typo-size('nav');
    @include typo-font('regular');

    margin-bottom: 2px;
    color: $C_GREEN_DARK;
}

.atom-image-label__text {
    @include typo-size('label');
    @include typo-font('light');

    margin-bottom: 5px;
}

.atom-image-label__link,
.atom-image-label__buy {
    @include typo-size('nav');
    @include typo-font('italic');

    color: $C_SECONDARY;

    &:hover {
        color: $C_PRIMARY;
    }
}
</style>
